import $ from 'jquery';

$(document).ready(function() {
  var $min_chars, $project_input;
  $project_input = $('#project_name_input');
  $min_chars = 3;
  setTimeout((function() {
    $project_input.on('input', function() {
      if ($project_input.val().length >= $min_chars) {
        $.ajax({
          url: '/projects/description',
          data: {
            "project_name": $project_input.val()
          },
          method: "GET"
        }).done(function(i) {
          $('#vacancy_description').val(i);
        });
      }
    });
  }), 1000);
});
