import $ from 'jquery';
import swal from './admin/plugins/sweetalert2.min';
import { showSuccessAlert } from './showSuccessAlert'

export const confirmDeleteAlert = (container = $('.confirm-action')) => {
  container.click(function (e) {
    e.preventDefault();
    e.stopPropagation();

    const href = $(this).attr('href');
    const method = $(this).data('method');
    const blockId = $(this).attr('id');
    const checkedItems = $('.time-tracking-mass-delete-checkbox:checked');
    const ids = $('.time-tracking-mass-delete-checkbox:checked').map((_, e) => e.value ).get();
    const csrfToken = $('meta[name="csrf-token"]').attr('content');

    return swal({
      title: "Are you sure?",
      text: $(this).attr('confirm_message'),
      type: "warning",
      showCancelButton: true,
      showConfirmButton:true,
      focusConfirm: true,
      cancelButtonText: "Cancel",
      confirmButtonText: 'Confirm',
      confirmButtonClass: "btn btn-warning",
      cancelButtonClass: "btn btn-info",
    })
      .then( function() {
        $.ajax({
          method: method,
          url: href,
          dataType: 'json',
          data: { time_tracking_ids: ids },
          headers: { 'X-CSRF-Token': csrfToken },
          success: function(response) {
            const { status } = response;
            const itemsCount = checkedItems ? checkedItems.length : 1;
            const header = itemsCount > 1 ? checkedItems.length + " items" : " 1 item";
            const message = (itemsCount > 1 ? "have" : "has") + " been successfully deleted!";
            const ms = 2000;

            if (status === 200 || status === 'ok') {
                showSuccessAlert(header, message, ms);
            } else {
              paperDashboard.showSidebarMessage(response.error, 'danger');
            }
          },
          error: function() {
            swal("Error", "There was a network error.<br/>Please check your internet connection.", "error");
          }
        });
      }).catch(swal.noop);
  });
}
