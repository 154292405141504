import $ from 'jquery'

import select2 from '../vendor/select2-full';
select2();

export function select2Search(id, {
  optionValue = 'id'
} = {}) {

  $(id).select2({
    theme: 'bootstrap',
    width: '100%',
    ajax: {
      dataType: 'json',
      delay: 250,
      data: function (params) {
        const select2_search = this.closest(".select2-hidden-accessible");
        let q_params = {
          'name_cont': params.term,
          'current_employee': !!select2_search.attr('data-current-employee'),
          'employee': !!select2_search.attr('data-employee'),
          'candidate': !!select2_search.attr('data-candidate'),
          'identifier': select2_search.attr('data-identifier')
        };

        return {
          q: q_params,
          page: params.page
        };
      },
      processResults: function (data, params) {
        params.page = params.page || 1;
        return {
          results: data,
          pagination: {
            more: (params.page * 30) < data.length
          }
        };
      },
      cache: true
    },
    allowClear: true,
    placeholder: function () {
        this.closest(".select2-hidden-accessible").attr('data-placeholder');
    },

    escapeMarkup: function (markup) {
      return markup;
    },
    minimumInputLength: 1,
    templateResult: templateResult,
    templateSelection: templateSelection
  });

  function templateResult(object) {
    if (object.loading) return object;
    if (typeof object.email === 'undefined') return `<span value="${object[optionValue]}">${object.name}</span>`;
    return `<span value="${object[optionValue]}">${object.name} | ${object.email}</span>`;
  }

  function templateSelection(object) {
    return object.name || object.text;
  }
}
