import $ from 'jquery';

$(document).ready(function() {
  if (!$('.js-users-form').length) {
    return
  }

  $('.js-roles-input').select2({
    width: '100%'
  });
});
