import $ from 'jquery';

$(document).ready(function() {
  var password_inputs = $('.password_input_wrapper');

  password_inputs.each(function() {
    if ($(this).find('span.error').length == 1) {
      $(this).find('.input-group').addClass('has-danger');
    }
    else {
      $(this).removeClass('has-error');
    }
  });
});
