// Requests constants
import $ from 'jquery';
import c3 from 'c3'

$(function() {
  if (!$('.clean-air').length) {
    return;
  }
});

const workingDayHours = 8;

const tempValue = $('.tempValue');
const humValue = $('.humValue');
const co2Value = $('.co2Value');

const alertContainer = $('.alert');

const replies = {
  lowTemp: "Warm this up! Adjust the heater or use AC in heating mode.",
  highTemp: "What a hot party! Turn on AC or open the windows.",
  highCO2: "No air, no gain! Open the windows and get fresh.",
  lowHum: "Uh, are we in Sahara? Turn on humidifier or open the windows.",
  highHum: "Too wet, unless you are the rainforest flower. Open the window, adjust the heater or use AC."
};

let roomID = -1;
let isChartRendered = false;
const charts = [];

// Generare URL Request
function generateUrlRequest(type, hours) {
  return `rooms/${roomID}/sensor_values?type=${type}&hours=${hours}`;
}

// Dashboard cards
function updateDashboardCards() {
  $('.dashboard .card').each((i, el) => {
    let cardRoomID = $(el).data('room-id');
    let icon = $(el).find('.fa');
    let $temperature = $(el).find('.tempVal');
    let $humidity = $(el).find('.humVal');
    let $co2level = $(el).find('.co2Val');

    updateGeneralValues(cardRoomID, icon, $temperature, $humidity, $co2level);
  });
}

function initCharts() {
  let temperatureOptions = {
    containerSelector: '.chart1',
    type: 'temperature',
    hours: workingDayHours,
    color: '#ff7f0e',
    min: 10,
    max: 30,
    units: '°C',
    lines: [{value: 21, text: 'Min'}, {value: 23, text: 'Max'}]
  };
  let humidityOptions = {
    containerSelector: '.chart2',
    type: 'humidity',
    hours: workingDayHours,
    color: '#1f77b4',
    min: 0,
    max: 100,
    units: '%',
    lines: [{value: 40, text: 'Min'}, {value: 60, text: 'Max'}]
  };
  let co2Options = {
    containerSelector: '.chart3',
    type: 'co2',
    hours: workingDayHours,
    color: '#98df8a',
    min: 0,
    max: 2000,
    units: ' PPM',
    lines: [{value: 1000, text: 'Max'}]
  };
  createChart(temperatureOptions);
  createChart(humidityOptions);
  createChart(co2Options);
  isChartRendered = true;
}

function createChart(options) {
  charts[options.type] = c3.generate({
    bindto: options.containerSelector,
    data: {
      type: 'spline',
      url: generateUrlRequest(options.type, options.hours),
      mimeType: 'json',
      keys: {
        x: 'x',
        value: [options.type]
      },
      names: {
        value0: options.type
      }
    },
    color: {
      pattern: [options.color]
    },
    axis: {
      x: {
        type: 'timeseries',
        tick: {
          format: '%H:%M',
          fit: true
        }
      },
      y: {
        min: options.min,
        max: options.max,
        padding: {top: 0, bottom: 0},
        tick: {
          format: (data) => { return data + options.units; }
        }
      }
    },
    grid: {
      y: {
        lines: options.lines
      }
    },
    legend: {
      show: false
    },
    tooltip: {
      format: {
        title: function (d) {
          let format = d3.time.format('%d.%m.%y %H:%M');
          return format(d);
        },
        value: (value) => { return value + options.units }
      }
    }
  });
}

function updateChart(type, hours) {
  let urlRequest = generateUrlRequest(type, hours);

  charts[type].load({
    url: urlRequest,
    mimeType: 'json',
    keys: {
      x: 'x',
      value: [type]
    },
    names: {
      value0: type
    }
  });
}

// Update General values
function updateGeneralValues(roomID, icon, $temperature = tempValue, $humidity = humValue, $co2level = co2Value) {
  $.get(`rooms/${roomID}/sensor_values/current`).then(function(response) {
    let { temperature, humidity, co2 } = response;

    $temperature.text(temperature);
    $humidity.text(humidity);
    $co2level.text(co2);

    checkAlerts(icon, $temperature, $humidity, $co2level);
  });
}

// Alerts
function checkAlerts(icon, $temperature, $humidity, $co2level) {
  const tempValue = $temperature.text();
  const humValue = $humidity.text();
  const co2Value = $co2level.text();
  let isWarningExist;

  $temperature.add($co2level).add($humidity).removeClass('danger');

  if (tempValue < 19 || tempValue > 25 || co2Value > 1000 || humValue < 30 || humValue > 70) {
    isWarningExist = true;
  }

  if (roomID === -1) {
    !!isWarningExist ?
      icon.removeClass('fa-check').addClass('fa-exclamation-triangle') :
      icon.removeClass('fa-exclamation-triangle').addClass('fa-check');
  } else {
    !!isWarningExist ?
      alertContainer.text('').fadeIn() :
      alertContainer.fadeOut();
  }

  if (tempValue < 19) {
    $temperature.addClass('danger');
    alertContainer.append(`<p>${replies.lowTemp}</p>`)
  } else if (tempValue < 21) {
    $temperature.addClass('warning');
    alertContainer.append(`<p>${replies.lowTemp}</p>`)
  } else if (tempValue > 25) {
    $temperature.addClass('danger');
    alertContainer.append(`<p>${replies.highTemp}</p>`)
  } else if (tempValue > 23) {
    $temperature.addClass('warning');
    alertContainer.append(`<p>${replies.highTemp}</p>`)
  }

  if (co2Value > 1200) {
    $co2level.addClass('danger');
    alertContainer.append(`<p>${replies.highCO2}</p>`)
  } else if (co2Value > 1000) {
    $co2level.addClass('warning');
    alertContainer.append(`<p>${replies.highCO2}</p>`)
  }

  if (humValue < 30) {
    $humidity.addClass('danger');
    alertContainer.append(`<p>${replies.lowHum}</p>`)
  } else if (humValue < 40) {
    $humidity.addClass('warning');
    alertContainer.append(`<p>${replies.lowHum}</p>`)
  } else if (humValue > 70) {
    $humidity.addClass('danger');
    alertContainer.append(`<p>${replies.highHum}</p>`)
  } else if (humValue > 60) {
    $humidity.addClass('warning');
    alertContainer.append(`<p>${replies.highHum}</p>`)
  }
}

// Request last values in the room
updateDashboardCards();

// Switch tabs
const tabs = $('.js-cleanair-room');

tabs.click(function(e) {
  roomID = $(e.target).data('room-id');

  if (roomID === -1) {
    $('.tab-content').fadeOut();
    $('.dashboard').fadeIn();
    updateDashboardCards();
  } else {
    let cardWrapper = $('.tab-content');

    if (!isChartRendered) {
      initCharts();
    } else {
      updateChart("temperature", workingDayHours);
      updateChart("humidity", workingDayHours);
      updateChart("co2", workingDayHours);
    }

    updateGeneralValues(roomID);
    $('.dashboard').fadeOut();
    cardWrapper.fadeIn();
  }
});

// Buttons click handler
$('.requestInfoBtn').click(function(e) {
  const type = e.target.getAttribute("data-type");
  const hours = e.target.getAttribute("data-hours");

  $(`.requestInfoBtn[data-type=${type}]`).removeClass('active');
  $(e.target).addClass('active');

  updateChart(type, hours);
});

// Set Interval
let timer = setInterval(() => {
  if (roomID === -1) {
    updateDashboardCards();
  } else {
    updateGeneralValues(roomID);
    $('.requestInfoBtn.active').each((i, el) => {
      let type = el.getAttribute('data-type');
      let hours = el.getAttribute('data-hours');
      updateChart(type, hours);
    });
  }
}, 30000);

// Handle dashboard card click
$('.js-cleanair-room-card').click(e => {
  let roomId = e.currentTarget.getAttribute('data-room-id');

  $(`a[data-room-id=${roomId}]`).trigger("click");
});
