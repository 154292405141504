import $ from 'jquery';

$(document).ready(function() {
  const topicMode = () => {
    $('#toggle_section').hide();
    $('#wiki_page_title').attr('placeholder', 'Enter new topic name');
  };

  const sectionMode = () => {
    $('#toggle_section').show();
    $('#wiki_page_title').attr('placeholder', 'New section name');
  };

  const processCurrentSelection = () => {
    if ($('#wiki_page_topic_id').val() === "") {
      topicMode();
    } else {
      sectionMode();
    }
  };

  processCurrentSelection();
  $('#wiki_page_topic_id').on('change', () => {
    processCurrentSelection();
  });
});
