import $ from 'jquery';
import * as dateFns from 'date-fns'
import { confirmDeleteAlert } from './confirmDeleteAlert'
import { select2Search } from './admin/select2_search'
import { initializeTTmodal } from './time_trackings_form'
import { prefillSelectedObjects } from './admin'

$(document).ready(function () {
  let deleteBtn = $('#time-trackings-mass-delete-btn');
  let checkBoxes = $('.time-tracking-mass-delete-checkbox');
  let bulkCheckBox = $('.js-time-tracking-mass-delete-checkbox-bulk');
  $('.datepicker').on('dp.change', function(ev){
    if(ev.target.value) {
      $(this).parent().addClass('icon-hidden');
    }
  });

  $(document).on("click", ".js-show-datepicker-dropdown", function (e) {
    e.stopPropagation();
  });

  $(document).on("click", ".js-btn-log-day", function (e) {
    e.preventDefault();

    $.ajax({
      method: 'GET',
      url: e.currentTarget.getAttribute('href'),
    })
      .done(function (response) {
        $('#form-container').find('.modal-content').html(response);
        $('#form-container').modal();
        select2Search('#time_tracking_person_id');
        select2Search("#time_tracking_project_id");
        initializeTTmodal();
      })
  });

  $(document).on("click", ".js-time-track-edit-btn", function (e) {
    e.preventDefault();

    $.ajax({
      method: 'GET',
      url: e.target.attributes.href.textContent,
    }).done(function (response) {
      $('#form-container').find('.modal-content').html(response);
      $('#form-container').modal();
      select2Search("#time_tracking_project_id");
      prefillSelectedObjects();
      initializeTTmodal()
    })
  });

  bulkCheckBox.on('change', function (source) {
    $('.time-tracking-mass-delete-checkbox').map((_i, e) => {
      e.checked = bulkCheckBox[0].checked;
      showHideDeleteBtn();
    });
  });

  checkBoxes.on('change', function (e) {
    showHideDeleteBtn()
    $(this).closest("tr").toggleClass("selected");
  });

  function showHideDeleteBtn() {
    if ($('.time-tracking-mass-delete-checkbox:checked').length) {
      deleteBtn.show();
    } else {
      deleteBtn.hide();
    }
  }

  confirmDeleteAlert(deleteBtn);

  $('#time_tracking_started_at').on('dp.change', function (e) {
    let finish_date = new Date(e.date._d);
    let original_finish_date = $('#time_tracking_finished_at').data('DateTimePicker').date()._d;

    finish_date.setHours(original_finish_date.getHours());
    finish_date.setMinutes(original_finish_date.getMinutes());

  $('#time_tracking_finished_at').data('DateTimePicker').date(finish_date);
  });

  fillDateFieldWithButton();
  checkSelectInSearchForm();
  fillDateFieldWithDatePicker();
  clearSearchForm();
  changePrevMonthDate();
  scrollToDetailedReport();
});

function fillDateFieldWithButton() {
  const dateFilter = $('.js-date-filter');

  dateFilter.on('click', function (e) {
    const newStartedAt = e.target.dataset.startedAt,
          newFinishedAt = e.target.dataset.finishedAt;

    changeDatePickerInputs(newStartedAt, newFinishedAt);
    updateDatePickerText(getDatePickerButtonText([newStartedAt, newFinishedAt]));
  });
}

function checkSelectInSearchForm() {
  const checkSelectInForm = $('.js-time-trackings-search-submit');

  checkSelectInForm.on('click', function (e) {
    const personId = Number($('#q_person_id_eq').val()),
      projectId = Number($('#q_project_id_eq').val());

    if (personId === 0 && projectId === 0) {
      e.preventDefault();
      paperDashboard.showSidebarMessage('Please select an employee or project first', 'danger');
    }
  });
}

function fillDateFieldWithDatePicker() {
  const showDatePicker = $('.dropdown');

  showDatePicker.on('dp.change', function (e) {
    const startedAt = $('.js-started-at').val(),
          finishedAt = $('.js-finished-at').val();

    updateDatePickerText(getDatePickerButtonText([startedAt, finishedAt]));
  });
}

function changePrevMonthDate() {
  const changeDateForPrevMonth = $('.js-prev-month');

  changeDateForPrevMonth.on('click', function() {
    const strStartedAtValue = $('.js-started-at').val();
          startedAtDate = getNewDateFromString(strStartedAtValue),
          newStartedAt = getBeginningOfTheMonth(startedAtDate),
          newFinishedAt = getEndOfTheMonth(startedAtDate);

    changeDatePickerInputs(newStartedAt, newFinishedAt);
    updateDatePickerText(getDatePickerButtonText([newStartedAt, newFinishedAt]));
    });
}

function clearSearchForm() {
  const clearSearchFormButton = $('.js-clear-form');

  clearSearchFormButton.click( function() {
    $("#q_person_id_eq").val(null).trigger("change");
    $("#q_project_id_eq").val(null).trigger("change");
    $('.js-started-at')[0].value = '';
    $('.js-finished-at')[0].value = '';
    setDatepickerText();
  });
}

function getNewDateFromString(date) {
  const newDate = dateFns.parse(date, "dd-MM-yyyy", new Date());

  if (dateFns.isValid(newDate)) {
    return newDate;
  } else {
    return new Date();
  }
}

function getDatePickerButtonText(strDates) {
  return strDates.map( strDate => {
    if (strDate !== "") {
      const date = getNewDateFromString(strDate);

      return dateFns.format(date, "dd.MM.yy");
    }
    else return "";
  }).join('-');
}

function getBeginningOfTheMonth(date) {
  let newDate = new Date(date.getFullYear(), date.getMonth() - 1, 1);

  return dateFns.format(newDate, "dd-MM-yyyy");
}

function getEndOfTheMonth(date) {
  let newDate = new Date(date.getFullYear(), date.getMonth(), 0);

  return dateFns.format(newDate, "dd-MM-yyyy");
}

function updateDatePickerText(buttonText) {
  if ($('.js-show-datepicker').attr('aria-expanded') === 'true') {
    document.querySelector('.js-show-datepicker').innerHTML = buttonText;
  }
}

function changeDatePickerInputs(startedAt, finishedAt) {
  $('.js-started-at').val(startedAt);
  $('.js-finished-at').val(finishedAt);
}

function setDatepickerText() {
  if ($(".js-started-at").val() === "" || $(".js-finished-at").val() === "") {
    document.querySelector(".js-show-datepicker").innerHTML = "Pick a time";
  }
}

function scrollToDetailedReport() {
  const urlParams = new URLSearchParams(new URL(document.URL).search);

  if (urlParams.has('page') || urlParams.has('q[s]')) {
    scroll(0, $(".js-time-tracking-table-title").position().top);
  }
}
