import $ from 'jquery';
import { setUpDatepicker } from './datepicker';
import { setUpSelectpicker } from './admin'
import { confirmDeleteAlert } from './confirmDeleteAlert'

$(document).ready(function() {
  $(".time-trackings-daily-data").each((_, element) => {
    setupDailyCardRequestHooks($(element));
  });

  $(".js-month-dropdown")
    .datetimepicker()
    .on("dp.change", (e) => {
      const parser = new URL(window.location);
      parser.searchParams.set("month", e.target.value);
      window.location = parser.href;
    });

  $(".time-trackings-daily-form textarea[name=\"time_tracking[description]\"").one("change keyup paste", enableDailyFormActionButtons);

  setupArrowRotationIcons();
  initTimeTrackingRowHighlighting();
  cleanFormOnCancel();
});

function setupArrowRotationIcons() {
  const monthDropdown = $(".js-month-dropdown");
  const datepickerInput = $(".datepicker.time-input");

  monthDropdown.on("focus", function() { $(".month-arrow").addClass("focused"); });
  monthDropdown.on("blur", function() { $(".month-arrow").removeClass("focused"); });

  datepickerInput.on("focus", function() {
    $(this).closest(".time-input-container").addClass("focused");
  });

  datepickerInput.on("blur", function() {
    $(this).closest(".time-input-container").removeClass("focused");
  });
}

function initTimeTrackingRowHighlighting() {
  const btnEdit = $(".time_tracking_edit_btn");

  btnEdit.on("click", function() {
    const rowToEdit = $(this).closest(".js-time-tracking-record-row");
    const dayToEdit = rowToEdit.closest(".time-trackings-daily-data");
    const cancelBtn = dayToEdit.find('.js-tt-cancel-btn');
    const form = dayToEdit.find(".time-trackings-daily-form");

    cancelBtn.on('click', clearTimeTrackingForm(form));
  });
}

function cleanFormOnCancel() {
  const btnCancel = $('.js-tt-cancel-btn');

  btnCancel.on("click", function() {
    const form = $(this).closest(".time-trackings-daily-form");

    clearTimeTrackingForm(form);
  });
}

function clearTimeTrackingForm(form) {
  form.find("input:not(.btn.btn-success.btn-fit-h)").val("");
  form.find('.form-control').val(null);
  form.find(".selectpicker").selectpicker("refresh");
  form.find(".selectpicker").on('change', enableDailyFormActionButtons);
  form.find('.time_tracking_started_at .form-control.datepicker.time-input').val('10:00');
  form.find('.time_tracking_finished_at .form-control.datepicker.time-input').val('18:00');
  form.find(".btn-save .btn").attr("disabled", true);
  form.find(".btn-cancel .btn").attr("disabled", true);
  form.find('.form-group .text-danger').remove();
}

function enableDailyFormActionButtons() {
  $(this).closest('.time-trackings-daily-form').find('.btns-container .btn').attr('disabled', false);
}

const setupDailyCardRequestHooks = (container) => {
  container.find("form").each((_, form) => {
    setupRemoteRequestHooks(form, container);
  });

  container.find(".time_tracking_delete_btn").each((_, btn) => {
    setupRemoteRequestHooks(btn, container);
  });

  container.find(".time_tracking_edit_btn").each((_, btn) => {
    setupRemoteRequestHooks(btn, container);
  });
};

const setupRemoteRequestHooks = (element, container) => {
  $(element).on("ajax:success", ({ originalEvent }) => {
    const response = originalEvent.detail[2].response
    container.html(response);
    container.find(".datepicker").each(setUpDatepicker);
    container.find(".datepicker").on("dp.change", enableDailyFormActionButtons);
    container.find(".selectpicker").each(setUpSelectpicker);
    container.find(".selectpicker").on('change', enableDailyFormActionButtons);
    container.find(".time_tracking_description textarea").one("change keyup paste", enableDailyFormActionButtons);
    container.find(".js-tt-cancel-btn").on('click', function() {
      clearTimeTrackingForm(form);
      form.removeClass('is-editing');
      container.find('.selected').removeClass('selected');

      $(form).attr('action', `/mass_time_trackings?day=${$(form).attr('date')}`);
    });
    container.find(".btn-edit").on('click', function() {
      container.addClass("selected");
      form.addClass("is-editing");
    });

    if ($(element).hasClass("btn-edit") || container.find('.form-group .text-danger').length > 0) {
      container.find(".time-trackings-daily-form .btn-cancel .btn").attr("disabled", false);
      $(".tooltip").remove();
    }

    setupDailyCardRequestHooks(container);
    confirmDeleteAlert();
    $('[data-toggle="tooltip"], [rel="tooltip"]').tooltip({ trigger: 'hover' });
  });

  $(element).on("ajax:error", ({ originalEvent }) => {
    const error = originalEvent.detail[0].error;
    const form = $(element).closest(".time-trackings-daily-form");
    clearTimeTrackingForm(form);
    paperDashboard.showSidebarMessage(error, 'danger')
  });
};
