import $ from 'jquery';

$(document).ready(function() {
  if (!$('.js-project-form').length) {
    return
  }

  $('.js-members-input').select2({
    width: '100%',
    ajax: {
      delay: 250,
      processResults: (data) => {
        return {
          results: data.map((item) => { return { id: item.id, text: item.label } })
        };
      }
    }
  });

  $(".js-team-members").on("change", function () {
    const selectedMembers = $(this).val() || [];
    const pastMembersSelect = $(".js-past-members");

    const pastMembers = pastMembersSelect.val() || [];
    const allMemberOptions = $(this).find("option");

    allMemberOptions.each(function () {
      const memberId = $(this).val();

      if (!selectedMembers.includes(memberId) && !pastMembers.includes(memberId)) {
        pastMembersSelect.append(
          $("<option>", { value: memberId, text: $(this).text() })
        );

        pastMembers.push(memberId);
        pastMembersSelect.val(pastMembers);
      }
    });

    allMemberOptions.each(function () {
      if (!selectedMembers.includes($(this).val())) {
        $(this).remove();
      }
    });
  });
});

