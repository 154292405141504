import $ from 'jquery'
import * as dateFns from 'date-fns'
import { setUpDatepicker } from './datepicker'
import { showNewLogAlert } from './showNewLogAlert'

export function initializeTTmodal() {
  setUpDatepicker('DD-MM-YYYY HH:mm', '#time_tracking_started_at')
  setUpDatepicker('DD-MM-YYYY HH:mm', '#time_tracking_finished_at')
  changeFinishedAtByValue('#time_tracking_started_at')

  handleAjaxResponse('#form-container')

  function closeModalForm() {
    $('#form-container').modal('hide')
  }

  $('.js-log-day-form-cancel').on('click', function () {
    closeModalForm()
  })

  $('#time_tracking_started_at').on('dp.change', function () {
    const startedAt = $('#time_tracking_started_at').val(),
      finishedAt = $('#time_tracking_finished_at').val()

    changeFinishedAtDate(startedAt, finishedAt)
  })

  function changeFinishedAtByValue(startedAtId) {
    $(startedAtId).change(function () {
      const startedAt = $(startedAtId).val(),
        finishedAt = $('#time_tracking_finished_at').val()

      if (!/[^a-zA-Z\d\s:]/.test(startedAt)) {
        changeFinishedAtDate(startedAt, finishedAt)
      }
    })
  }

  function changeFinishedAtDate(startedAt, finishedAt) {
    const startedAtDate = dateFns.parse(startedAt, 'dd-MM-yyyy HH:mm', new Date())
    let updatedFinishedAt

    if (finishedAt !== '') {
      const finishedAtDate = dateFns.parse(finishedAt, 'dd-MM-yyyy HH:mm', new Date())
      updatedFinishedAt = dateFns.set(startedAtDate, {
        hours: finishedAtDate.getHours(),
        minutes: finishedAtDate.getMinutes(),
      })
    } else {
      updatedFinishedAt = dateFns.set(startedAtDate, { hours: 18, minutes: 0 })
    }

    $('#time_tracking_finished_at').val(
      dateFns.format(updatedFinishedAt, 'dd-MM-yyyy HH:mm'),
    )
  }

  function handleAjaxResponse(modalFormId) {
    $(modalFormId).on('ajax:success', function({ originalEvent }) {
      const { data, status, error } = originalEvent.detail[0]

      if (status === 200 || status === 201) {
        const { project, started_at, finished_at } = data

        const milliseconds = new Date(finished_at) - new Date(started_at)
        const hours = Math.floor(milliseconds / 36e5)
        const hoursStr = hours > 0 ? hours + ' Hours ' : ''
        const minutes = dateFns.getMinutes(milliseconds)
        const minutesStr = minutes > 0 ? minutes + ' Minutes ' : ''
        const messageType = status === 200 ? 'updated': 'added'
        const header = `${hoursStr} ${minutesStr} to project ${project}`
        const message = `were successfully ${messageType}!`
        const ms = 4000

        $(modalFormId).modal('hide')
        showNewLogAlert(header, message, ms)
      } else {
        const errorMessages = status === 403 ? error : error.join(', ')

        $('#time-tracking-form .error-messages').text(errorMessages)
      }
    })
  }
}
