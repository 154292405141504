/*!
 =========================================================
 * Paper Dashboard 2 PRO - v2.0.1
 =========================================================
 * Product Page: https://www.creative-tim.com/product/paper-dashboard-2-pro
 * Copyright 2018 Creative Tim (http://www.creative-tim.com)
 * Designed by www.invisionapp.com Coded by www.creative-tim.com
 =========================================================
 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */
/* External libraries */

import $ from 'jquery'
import { select2Search } from './admin/select2_search'
import { confirmDeleteAlert } from './confirmDeleteAlert'

import select2 from './vendor/select2-full';
select2();

transparent = true;
transparentDemo = true;
fixedTop = false;

navbar_initialized = false;
backgroundOrange = false;
sidebar_mini_active = false;
toggle_initialized = false;

seq = 0, delays = 80, durations = 500;
seq2 = 0, delays2 = 80, durations2 = 500;

// Returns a function, that, as long as it continues to be invoked, will not
// be triggered. The function will be called after it stops being called for
// N milliseconds. If `immediate` is passed, trigger the function on the
// leading edge, instead of the trailing.

function debounce(func, wait, immediate) {
  var timeout;
  return function () {
    var context = this,
      args = arguments;
    clearTimeout(timeout);
    timeout = setTimeout(function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    }, wait);
    if (immediate && !timeout) func.apply(context, args);
  };
};

matcher = window.matchMedia('(prefers-color-scheme: dark)');
matcher.addListener(onUpdate);
onUpdate();

function onUpdate() {
  const lightSchemeIcon = document.querySelector('link#light-scheme-icon');
  const darkSchemeIcon = document.querySelector('link#dark-scheme-icon');

  if (matcher.matches) {
    lightSchemeIcon.remove();
    document.head.append(darkSchemeIcon);
  } else {
    document.head.append(lightSchemeIcon);
    darkSchemeIcon.remove();
  }
}

(function () {
  const isWindows = navigator.platform.indexOf('Win') > -1

  if (isWindows) {
    $('html').addClass('perfect-scrollbar-on');
  } else {
    $('html').addClass('perfect-scrollbar-off');
  }
})();

export function prefillSelectedObjects() {
  $('.select2-search-object').each(function () {
    const objects = $(this).data('selectedObjects');
    if (objects) {
      objects.forEach((object) => {
        var newOption = new Option(object.name, object.id, false, false);
        $(this).append(newOption);
      });
      $(this).val(objects.map((arr) => arr.id));
    }
  });
}

export var setUpSelectpicker = function () {
  $(this).selectpicker({
    iconBase: "nc-icon",
    tickIcon: "nc-check-2",
    actionsBox: this.classList.contains("action-box")
  });
};

var switchOffAnother = function () {
  let current = $(this)
  const another = findAnotherCheckbox(current);
  if (another.is(":checked"))
    another.prop('checked', !current.is(':checked'));
}

$(document).ready(function () {
  prefillSelectedObjects();

  select2Search('.select2-search-object');

  //  Activate the Tooltips
  $('[data-toggle="tooltip"], [rel="tooltip"]').tooltip({
    trigger: 'hover'
  });

  $('.only-one').on('change', switchOffAnother);

  $('.required-mark').prepend("<abbr title='required'>*</abbr> ");

  // Activate Popovers and set color for popovers
  $('[data-toggle="popover"]').each(function () {
    color_class = $(this).data('color');
    $(this).popover({
      template: '<div class="popover popover-' + color_class + '" role="tooltip"><div class="arrow"></div><h3 class="popover-header"></h3><div class="popover-body"></div></div>'
    });
  });

  $("input[data-autocomplete]").each(function () {
    var url = $(this).data('autocomplete').url;
    var input_id = $(this).data('autocomplete').field_id;
    $(this).autocomplete({
      source: url,
      change: function (event, ui) {
        if (!ui.item) return;
        $("#" + input_id).val(ui.item.id);
      }
    });
  });

  paperDashboard.checkFullPageBackgroundImage();

  // Activate js-tags-input
  $('.js-tags-input').select2({
    tags: true,
  });

  //    Activate bootstrap-select
  if ($(".selectpicker").length != 0) {
    $(".selectpicker").each(setUpSelectpicker);
  }

  //  Append modals to <body>
  if ($(".modal").length != 0) {
    $('.modal').appendTo('body');
  }

  if ($('.full-screen-map').length == 0 && $('.bd-docs').length == 0) {
    // On click navbar-collapse the menu will be white not transparent
    $('.collapse').on('show.bs.collapse', function () {
      $(this).closest('.navbar').removeClass('navbar-transparent').addClass('bg-white');
    }).on('hide.bs.collapse', function () {
      $(this).closest('.navbar').addClass('navbar-transparent').removeClass('bg-white');
    });
  }

  paperDashboard.initMinimizeSidebar();

  $navbar = $('.navbar[color-on-scroll]');
  scroll_distance = $navbar.attr('color-on-scroll') || 500;

  // Check if we have the class "navbar-color-on-scroll" then add the function to remove the class "navbar-transparent" so it will transform to a plain color.
  if ($('.navbar[color-on-scroll]').length != 0) {
    paperDashboard.checkScrollForTransparentNavbar();
    $(window).on('scroll', paperDashboard.checkScrollForTransparentNavbar)
  }

  $('.form-control').on("focus", function () {
    $(this).parent('.input-group').addClass("input-group-focus");
  }).on("blur", function () {
    $(this).parent(".input-group").removeClass("input-group-focus");
  });

  confirmDeleteAlert();

  showFlash();
  appendSelectpickerToContactForm();
});

$(document).on('click', '.navbar-toggle', function () {
  $toggle = $(this);

  if (paperDashboard.misc.navbar_menu_visible == 1) {
    $('html').removeClass('nav-open');
    paperDashboard.misc.navbar_menu_visible = 0;
    setTimeout(function () {
      $toggle.removeClass('toggled');
      $('#bodyClick').remove();
    }, 550);

  } else {
    setTimeout(function () {
      $toggle.addClass('toggled');
    }, 580);

    div = '<div id="bodyClick"></div>';
    $(div).appendTo('body').click(function () {
      $('html').removeClass('nav-open');
      paperDashboard.misc.navbar_menu_visible = 0;
      setTimeout(function () {
        $toggle.removeClass('toggled');
        $('#bodyClick').remove();
      }, 550);
    });

    $('html').addClass('nav-open');
    paperDashboard.misc.navbar_menu_visible = 1;
  }
});

$(window).resize(function () {
  // reset the seq for charts drawing animations
  seq = seq2 = 0;

  if ($('.full-screen-map').length == 0 && $('.bd-docs').length == 0) {
    $navbar = $('.navbar');
    isExpanded = $('.navbar').find('[data-toggle="collapse"]').attr("aria-expanded");
    if ($navbar.hasClass('bg-white') && $(window).width() > 991) {
      $navbar.removeClass('bg-white').addClass('navbar-transparent');
    } else if ($navbar.hasClass('navbar-transparent') && $(window).width() < 991 && isExpanded != "false") {
      $navbar.addClass('bg-white').removeClass('navbar-transparent');
    }
  }
});

paperDashboard = {
  misc: {
    navbar_menu_visible: 0
  },

  checkScrollForTransparentNavbar: debounce(function () {
    if ($(document).scrollTop() > scroll_distance) {
      if (transparent) {
        transparent = false;
        $('.navbar[color-on-scroll]').removeClass('navbar-transparent');
      }
    } else {
      if (!transparent) {
        transparent = true;
        $('.navbar[color-on-scroll]').addClass('navbar-transparent');
      }
    }
  }, 17),

  checkSidebarImage: function () {
    $sidebar = $('.sidebar');
    image_src = $sidebar.data('image');

    if (image_src !== undefined) {
      sidebar_container = '<div class="sidebar-background" style="background-image: url(' + image_src + ') "/>'
      $sidebar.append(sidebar_container);
    }
  },

  initMinimizeSidebar: function () {
    $('#minimizeSidebar').click(function () {
      var $btn = $(this);

      if (paperDashboard.misc.sidebar_mini_active == true) {
        $('body').removeClass('sidebar-mini');
        paperDashboard.misc.sidebar_mini_active = false;
      } else {
        $('body').addClass('sidebar-mini');
        paperDashboard.misc.sidebar_mini_active = true;
      }

      // we simulate the window Resize so the charts will get updated in realtime.
      var simulateWindowResize = setInterval(function () {
        window.dispatchEvent(new Event('resize'));
      }, 180);

      // we stop the simulation of Window Resize after the animations are completed
      setTimeout(function () {
        clearInterval(simulateWindowResize);
      }, 1000);
    });
  },

  startAnimationForLineChart: function (chart) {

    chart.on('draw', function (data) {
      if (data.type === 'line' || data.type === 'area') {
        data.element.animate({
          d: {
            begin: 600,
            dur: 700,
            from: data.path.clone().scale(1, 0).translate(0, data.chartRect.height()).stringify(),
            to: data.path.clone().stringify(),
            easing: Chartist.Svg.Easing.easeOutQuint
          }
        });
      } else if (data.type === 'point') {
        seq++;
        data.element.animate({
          opacity: {
            begin: seq * delays,
            dur: durations,
            from: 0,
            to: 1,
            easing: 'ease'
          }
        });
      }
    });

    seq = 0;
  },
  startAnimationForBarChart: function (chart) {

    chart.on('draw', function (data) {
      if (data.type === 'bar') {
        seq2++;
        data.element.animate({
          opacity: {
            begin: seq2 * delays2,
            dur: durations2,
            from: 0,
            to: 1,
            easing: 'ease'
          }
        });
      }
    });

    seq2 = 0;
  },
  showSidebarMessage: function (message, type, options = {}) {
    try {
      $.notify({
        icon: "nc-icon nc-bell-55",
        message: message
      }, {
        type: type,
        timer: 4000,
        placement: {
          from: 'top',
          align: 'right'
        },
        ...options
      });
    } catch (e) {
      console.log('Notify library is missing, please make sure you have the notifications library added.');
    }

  },
  checkFullPageBackgroundImage: function () {
    $page = $('.full-page');
    image_src = $page.data('image');

    if (image_src !== undefined) {
      image_container = '<div class="full-page-background" style="background-image: url(' + image_src + ') "/>';
      $page.append(image_container);
    }
  },
};

function hexToRGB(hex, alpha) {
  var r = parseInt(hex.slice(1, 3), 16),
    g = parseInt(hex.slice(3, 5), 16),
    b = parseInt(hex.slice(5, 7), 16);

  if (alpha) {
    return "rgba(" + r + ", " + g + ", " + b + ", " + alpha + ")";
  } else {
    return "rgb(" + r + ", " + g + ", " + b + ")";
  }
}

function showFlash() {
  let contentContainer = $('.content');

  let flashClass = contentContainer.data('flash-class');
  let flashMessage = contentContainer.data('flash-message');

  if (flashClass && flashMessage) {
    $.notify({
      message: flashMessage
    }, {
      type: flashClass,
      timer: 5000,
      placement: {
        from: 'top',
        align: 'center'
      }
    });
  }
}

function appendSelectpickerToContactForm() {
    $('.js-add-phones').click(function() {
      setTimeout( () => { $(".selectpicker").each(setUpSelectpicker); }, 20);
  });
}

function findAnotherCheckbox(current) {
  if (current.attr('id') == 'pe_payment_method_default')
    return $('#payoneer_payment_method_default');
  else
    return $('#pe_payment_method_default');
}
