import $ from 'jquery';
import moment from 'moment';

export var setUpDatepicker = function (index, input) {
  var $input = $(input);
  var defaultOpts = {
    icons: {
      time: "fa fa-clock-o",
      date: "fa fa-calendar",
      up: "fa fa-chevron-up",
      down: "fa fa-chevron-down",
      previous: 'fa fa-chevron-left',
      next: 'fa fa-chevron-right',
      today: 'fa fa-screenshot',
      clear: 'fa fa-trash',
      close: 'fa fa-remove'
    },
    format: 'DD-MM-YYYY',
    viewMode: 'days'
  };

  var opts = $.extend({}, defaultOpts, $input.data()?.date);

  $input.on("dp.show", () => $input.data("DateTimePicker").viewMode(opts.viewMode));
  $input.datetimepicker(opts);
};

$(document).ready(function () {
  if (moment && moment.updateLocale)
    moment.updateLocale('en', {
      week: {dow: 1} // Monday is the first day of the week
    });

  $('.datepicker').each(setUpDatepicker);
});
