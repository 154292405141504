import swal from './admin/plugins/sweetalert2.min';

export const showSuccessAlert = (header, message, ms, location='') => {
  return (
    swal({
      html: `<div class="delete-success tt-alert">
        <div class="hooray-img"></div>
        <div class="items-count-text">${header}</div>
        <div class="info-text">${message}</div>
      </div>`,
      showCloseButton: false,
      showConfirmButton: false,
      timer: ms,
    })
      .then( window.location.replace(location) )
      .catch(swal.noop)
  );
};
