import $ from 'jquery';

$(document).ready(function() {
  $('.js-day-off').on('change', function (e) {
    e.preventDefault();
    var action_url = e.target.dataset.holiday;
    var day_off = e.target.checked;
    var data = {
      holiday: {
        day_off: day_off
      }
    };
    const csrfToken = $('meta[name="csrf-token"]').attr('content');

    $.ajax({
      method: 'PATCH',
      url: action_url,
      data: data,
      dataType: 'json',
      headers: { 'X-CSRF-Token': csrfToken },
    }).done(function () {
      paperDashboard.showSidebarMessage("Success!", 'success', {
        delay: 500,
        timer: 1000
      })
    })
      .fail(function (){
        paperDashboard.showSidebarMessage("Something went wrong", 'error',{
          delay: 500,
          timer: 1000
        })
      })
  })
});
