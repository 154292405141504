import swal from './admin/plugins/sweetalert2.min';

export const showNewLogAlert = (header, message, ms) => {
  return (
    swal({
      html: `<div class="log-success tt-alert">
        <div class="time-img"></div>
        <div class="items-count-text">${header}</div>
        <div class="info-text">${message}</div>
      </div>`,
      showCloseButton: false,
      showConfirmButton: false,
      timer: ms,
    })
    .then(window.location.reload())
    .catch(swal.noop)
  );
};
