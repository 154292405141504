import Rails from '@rails/ujs'
import $ from 'jquery'
Rails.start()
window.jQuery = $
window.$ = $

import 'bootstrap/dist/js/bootstrap'
import './admin/plugins/bootstrap-datetimepicker'
import './admin/plugins/bootstrap-notify'
import './admin/plugins/bootstrap-selectpicker'
import './admin/plugins/sweetalert2.min'
import './admin/projects'
import './admin/select2_search'
import './admin/users'
import './admin/vacancies'
import './admin'
import './clean_airs'
import './confirmDeleteAlert'
import './datepicker'
import './holiday'
import './login'
import './showNewLogAlert'
import './showSuccessAlert'
import './time_trackings'
import './time_trackings_daily_remote_requests'
import './time_trackings_form'
import './vacancy'
import './wiki_page'
