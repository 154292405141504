import $ from 'jquery';
import { debounce } from 'lodash'
import { slugify } from '../../../javascript/utils/slugify'

$(document).ready(function() {
  $('.add_requirements_fields').click(function(event){
    $('.requirements').append($(this).data('field'));
    return event.preventDefault();
  });

  $('.add_additional_requirements_fields').click(function(event){
    $('.additional_requirements').append($(this).data('field'));
    return event.preventDefault();
  });

  $('.add_responsibilities_fields').click(function(event){
    $('.responsibilities').append($(this).data('field'));
    return event.preventDefault();
  });

  updateVacancySlug();
});

function removeInput(element) {
  $(element).parent().remove();
}

function updateVacancySlug() {
  $("#vacancy_name").keyup(debounce(function() {
    const vacancyName = $("#vacancy_name").val(),
          slagName = slugify(vacancyName);

    $(".js-vacancy-slug").val(slagName);
  }, 500));
}
